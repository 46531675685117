import '../scss/app.scss';
import '../scss/app_top.scss';
import './svg-icon';

// import * as bootstrap from "bootstrap/dist/js/bootstrap.bundle";
import {Modal, Tooltip} from 'bootstrap'; //Collapse
import Favourites from "./favourites";
import ApiV1Offers from "./api.v1.offers";
import "./offersList";

import Glide from '@glidejs/glide';

import {AccordionOverride, scrollDownButton} from "./tools";

import OffersListActions from "./account/offers";
import PasswordChecker from "./tools/passwordChecker";
import HyerAnalytics from "./analytics";
import SlimSelect from "slim-select";

const favourites = new Favourites();
window.OffersListActions = new OffersListActions();
const passChecker = new PasswordChecker();
passChecker.start();

const analyticsData = new HyerAnalytics();
// console.log(analyticsData);
analyticsData.startAutomateActions();

window.addEventListener('DOMContentLoaded', () => {
    console.log('Ładowanie strony zostało zakończone / app.bundle');

    scrollDownButton();

    if (document.querySelector('.onetimeclk')) {
        document.querySelectorAll('.onetimeclk').forEach(button => {
            if (button.tagName === 'button') {
                button.addEventListener('click', e => {
                    e.target.disabled = true;
                })
            } else if (button.tagName === 'A') {
                button.dataset.used = false;
                button.addEventListener('click', e => {
                    if (e.target.dataset.used) {
                        e.preventDefault();
                        e.stopImmediatePropagation();
                        e.target.dataset.used = true;
                    }
                })
            }
        });
    }

    /***
     * Start slider - glidejs
     */
    if (document.querySelectorAll('.glide').length) {
        new Glide('.glide', {
            type: 'slider', // focusAt: 'center',
            startAt: 0, gap: 40, rewind: false, // hoverpause: true,
            perView: 2, breakpoints: {
                600: {
                    gap: 20, perView: 1
                }
            }
        }).mount();
    }
    if (document.querySelector('select.std:not([initialized])')) {
        document.querySelectorAll('select.std:not([initialized])').forEach(selectField => {
            selectField.setAttribute('initialized', 'initialized');
            new SlimSelect({
                select: selectField, settings: {
                    closeOnSelect: true,
                    selectAll: false,
                    allowDeselect: false,
                    disabled: selectField.disabled ? true : false,
                    searchText: 'Wyszukaj',
                    placeholderText: 'Wybierz z listy',
                    showSearch: false
                }
            });
        });
    }
    /***
     * Testimonials - glidejs
     */
    if (document.querySelectorAll('.glide-testimonials').length) {
        document.querySelectorAll('.glide-testimonials').forEach(glideElement => {
            let glideTestimonial = new Glide(glideElement, {
                type: 'carousel', // focusAt: 'center',
                // startAt: 0,
                // gap: 40,
                autoplay: '2500', rewind: true, // hoverpause: true,
                perView: 1,
            });

            glideTestimonial.mount();
        });
    }

    /***
     * Bootstrap controls - tooltip
     */
    Array.from(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
        .forEach(tooltipNode => {
            // new bootstrap.Tooltip(tooltipNode);
            new Tooltip(tooltipNode);
            // bootstrap.Tooltip(tooltipNode);
        });
    /***
     * Password hide/show button
     */
    Array.from(document.querySelectorAll('.pass-eye'))
        .forEach(passButton => {
            passButton
                .addEventListener('click', e => {
                    e.preventDefault();
                    const elem = e.currentTarget;
                    const fieldDest = elem.dataset.elementid;

                    if (fieldDest && document.getElementById(fieldDest)) {
                        const currStatus = parseInt(document.getElementById(fieldDest).dataset.currStatus ?? -1);
                        document.getElementById(fieldDest).setAttribute('type', currStatus < 0 ? 'text' : 'password');

                        if (currStatus < 0) {
                            elem.querySelector('i.bi').setAttribute('class', 'bi bi-eye-slash-fill');
                        } else {
                            elem.querySelector('i.bi').setAttribute('class', 'bi bi-eye-fill');
                        }

                        document.getElementById(fieldDest).dataset.currStatus = -1 * currStatus;
                    }

                });
        });

    const clearForm = (form) => {
        form.querySelector('form') && form.querySelector('form').reset();
        form.querySelectorAll('.invalid').forEach(elInvalid => {
            elInvalid.classList.remove('invalid');
            const elInpInvalid = elInvalid.querySelector(':invalid');
            if (elInpInvalid) {
                elInpInvalid.setCustomValidity('');
                elInpInvalid.classList.contains('can-validate') && elInpInvalid.classList.remove('can-validate');
            }

        })
    }

    /***
     * Button toggler -
     */
    Array.from(document.querySelectorAll('button[data-toggle-el]'))
        .forEach(togglerBtn => {
            togglerBtn.addEventListener('click', e => {
                // console.log('***************************');
                // console.log(e.target.dataset.meToo);
                // console.log(e.target.dataset.toggleEl);
                // console.log('***************************');
                if (e.currentTarget.dataset.meToo) {
                    e.currentTarget.classList.toggle('hide');
                }
                let classSelector = e.currentTarget.dataset.toggleEl;
                if (e.currentTarget.dataset.toggleEl.substr(0, 1) === '[') {
                    classSelector = JSON.parse(e.currentTarget.dataset.toggleEl);
                }
                if ('string' === typeof classSelector) {
                    // console.log('>>>> TO JEST STRING', classSelector);
                    document.querySelector(classSelector).classList.toggle('hide');
                    if (classSelector[i].indexOf('form') > -1) {
                        clearForm(document.querySelector(classSelector[i]));
                    }

                } else {
                    // console.log('>>>> TO JEST TABLICA', classSelector);
                    for (let i in classSelector) {
                        // console.log('>>>>> ELEMENET', classSelector[i]);
                        document.querySelector(classSelector[i]).classList.toggle('hide');
                        if (classSelector[i].indexOf('form') > -1) {
                            clearForm(document.querySelector(classSelector[i]));
                        }
                    }
                }
            });
            // }
        });

    Array.from(document.querySelectorAll('.accordion.one-must-open'))
        .forEach(accordionWrapper => {
            new AccordionOverride(accordionWrapper);
        });

    Array.from(document.querySelectorAll('input,select,textarea'))
        .forEach(formEl => {
            formEl.addEventListener('blur', e => e.target.classList.add('can-validate'));
        });

    Array.from(document.querySelectorAll('.modal'))
        .forEach(modalNode => {
            // new bootstrap.Modal(modalNode);
            new Modal(modalNode);
        });

    if (document.querySelector('.offers-result')) {
        const offersSearchEngine = new ApiV1Offers(document.querySelector('.offers-result'));
        offersSearchEngine.start(favourites);
    }

});

